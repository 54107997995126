<template>
  <div id="app">
    <Header />
    <main>
      <Game />
      <QuestionModal v-if="getIsQuestionModal" />
      <DrugModal v-if="getIsDrugModal" />
      <OnlySpecialistModal v-if="getIsOnlySpecialistModal" />
      <NotSpecialistModal v-if="getIsNotSpecialistModal" />
      <ReloadGameModal v-if="getIsReloadGameModal" />
      <WelcomeModal v-if="getIsWelcomeModal" />
      <HowToStartModal v-if="getIsHowToStartModal" />
      <HowToPlayModal v-if="getIsHowToPlayModal" />
      <GameOwerModal v-if="getIsGameOwerModal" />
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/global/Header.vue";
import Game from "./components/game/Game.vue";

import Footer from "./components/global/Footer.vue";
import QuestionModal from "./components/modals/QuestionModal.vue";
import DrugModal from "./components/modals/DrugModal.vue";
import OnlySpecialistModal from "./components/modals/system-modals/OnlySpecialistModal.vue";
import NotSpecialistModal from "./components/modals/system-modals/NotSpecialistModal.vue";
import ReloadGameModal from "./components/modals/system-modals/ReloadGameModal.vue";
import WelcomeModal from "./components/modals/system-modals/WelcomeModal.vue";
import HowToStartModal from "./components/modals/system-modals/HowToStartModal.vue";
import HowToPlayModal from "./components/modals/system-modals/HowToPlayModal.vue";
import GameOwerModal from "./components/modals/system-modals/GameOwerModal.vue";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    Header,
    Game,
    QuestionModal,
    DrugModal,
    OnlySpecialistModal,
    NotSpecialistModal,
    ReloadGameModal,
    WelcomeModal,
    HowToStartModal,
    HowToPlayModal,
    GameOwerModal,
    Footer
  },
  computed: {
    ...mapGetters([
      "getIsQuestionModal",
      "getIsDrugModal",
      "getIsReloadGameModal",
      "getIsOnlySpecialistModal",
      "getIsNotSpecialistModal",
      "getIsWelcomeModal",
      "getIsHowToStartModal",
      "getIsHowToPlayModal",
      "getIsGameOwerModal"
    ])
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/_reset";
@import "@/assets/scss/_fonts";
@import "@/assets/scss/_default";
</style>

<template>
  <div class="modal">
    <div class="modal-main">
      <div class="modal-main__rectangle"></div>
      <div class="modal-main__cursor"></div>
      <div class="modal-main__content">
        <div class="modal-main__title">Як розпочати?</div>
        <div class="modal-main__text">
          Роздивіться зображення, оберіть об'єкт і натисніть на червоний
          кружечок
        </div>
        <div
          class="modal-main__btn"
          @click="showHowToPlayModal()"
          onClick="gtag('event', 'click', { 'event_category': ' onboading_step_2'}) "
        >
          Далі
        </div>
        <div class="modal-main__skip" @click="closeModal()">
          Пропустити навчання
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "HowToStartModal",
  methods: {
    ...mapMutations(["setIsHowToStartModal", "setIsHowToPlayModal"]),
    showHowToPlayModal() {
      this.setIsHowToStartModal(false);
      this.setIsHowToPlayModal(true);
    },
    closeModal() {
      this.setIsHowToStartModal(false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";
.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 98;

  &-main {
    position: absolute;
    width: 100%;
    max-width: 315px;
    top: 50%;
    left: 50%;
    transform: translate(-7%, -78%);
    background: #ffffff;
    box-shadow: 0px 7px 61px rgba(0, 0, 0, 0.17);
    &__rectangle {
      position: absolute;
      top: 125px;
      left: -17px;
      width: 33.74px;
      height: 33.74px;
      transform: rotate(45deg);
      background: #fff;
    }
    &__cursor {
      position: absolute;
      top: 120px;
      left: -156px;
      width: 70px;
      height: 85px;
      background: url("~@/assets/img/cursor.svg") no-repeat;
    }
    &__content {
      padding: 28px 30px 19px;
      text-align: center;
    }
    &__title {
      margin-bottom: 7px;
      font-weight: 600;
      font-size: 32px;
      line-height: 39px;
      color: $green;
    }
    &__text {
      margin-bottom: 30px;
      font-weight: 300;
      font-size: 20px;
      line-height: 24px;
    }
    &__btn {
      width: 210px;
      height: 40px;
      margin: 0 auto 32px;
      background-color: $green;
      border-radius: 40px;
      font-weight: 600;
      font-size: 16px;
      line-height: 40px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      &:hover {
        &:after {
          transform: translateX(5px);
        }
      }
      &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 10px;
        height: 8px;
        margin-left: 5px;
        background: url("~@/assets/img/btn-arrow.svg") no-repeat;
        transition: all 0.5s;
      }
    }
    &__skip {
      font-size: 14px;
      line-height: 17px;
      text-decoration: underline;
      color: $gray-dark;
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 1199px) {
  .modal {
    &-main {
      max-width: 375px;
      transform: translate(-50%, -50%);
      &__rectangle {
        display: none;
      }
      &__cursor {
        display: none;
      }
      &__content {
        padding: 30px 20px 30px;
      }
      &__title {
        font-size: 22px;
        line-height: 25px;
      }
      &__text {
        font-size: 16px;
        line-height: 18px;
      }
      &__btn {
        margin: 0 auto 22px;
      }
    }
  }
}
</style>

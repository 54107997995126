<template>
  <div class="modal" @click.self="closeModal()">
    <div class="modal-main">
      <div class="modal-main__content">
        <div class="modal-main__text">
          Інформація для визначеного кола осіб: медичних і фармацевтичних
          працівників. Перед застосуванням обов’язково ознайомтеся з інструкцією
          для медичного застосування. Протипоказання та можливі побічні ефекти
          наведені в інструкції для медичного застосування. Чинні версії
          інструкцій для медичного застосування лікарських засобів доступні на
          сайті <a href="http://www.drlz.com.ua">www.drlz.com.ua</a>.
          Відпускається за рецептом. Зберігати в недоступному для дітей місці.
          Самолікування може бути шкідливим для вашого здоров’я. За додатковою
          інформацією звертатися до ТОВ «Тева Україна»,02152, м. Київ, просп. П.
          Тичини, 1-В, поверх 9-й; +38044 594 70 80;
          <a href="https://www.teva.ua">www.teva.ua.</a>
          CBG-UA-00321 exp. date 09/2022
        </div>
        <div class="modal-main__title">
          Чи є ви спеціалістом галузі охорони здоров'я?
        </div>
        <div class="modal-main__buttons">
          <div
            class="modal-main__btn modal-main__btn--yes"
            @click="closeModal()"
          >
            Так
          </div>
          <div
            class="modal-main__btn modal-main__btn--no"
            @click="notSpecialist()"
          >
            Ні
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "OnlySpecialistModal",
  methods: {
    ...mapMutations([
      "setOnlySpecialistModal",
      "setNotSpecialistModal",
      "setIsWelcomeModal"
    ]),
    closeModal() {
      this.setOnlySpecialistModal(false);
      if (window.innerWidth >= 568) {
        this.setIsWelcomeModal(true);
      }

      localStorage.setItem("onlySpecialistModal", false);
    },
    notSpecialist() {
      this.setOnlySpecialistModal(false);
      this.setNotSpecialistModal(true);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 90, 133, 0.6);
  z-index: 100;

  &-main {
    position: absolute;
    width: 100%;
    max-width: 900px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    box-shadow: 0px 7px 61px rgba(0, 0, 0, 0.17);

    &__content {
      padding: 50px 50px 44px 56px;
    }

    &__text {
      margin-bottom: 27px;
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
      a {
        color: $blue;
      }
    }
    &__title {
      margin-bottom: 35px;
      font-weight: 600;
      font-size: 25px;
      line-height: 31px;
      text-align: center;
      color: $green;
    }
    &__buttons {
      display: flex;
      justify-content: center;
    }
    &__btn {
      width: 120px;
      height: 42px;
      margin: 0 11px;
      border: 2px solid $blue;
      border-radius: 100px;
      font-weight: 600;
      font-size: 15px;
      line-height: 40px;
      text-align: center;
      color: $blue;
      transition: all 0.5s;
      cursor: pointer;
      &--yes {
        background-color: $blue;
        color: #fff;
        &:hover {
          background-color: $purple;
          border-color: $purple;
        }
      }
      &--no {
        &:hover {
          background-color: $purple;
          border-color: $purple;
          color: #fff;
        }
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .modal {
    &-main {
      max-width: 615px;
      &__content {
        padding: 46px 95px 65px;
      }
      &__title {
        margin-bottom: 36px;
        font-size: 24px;
        line-height: 29px;
        color: $black;
      }
      &__text {
        display: none;
      }
      &__btn {
        width: 200px;
        height: 56px;
        line-height: 56px;
      }
    }
  }
}
@media screen and (max-width: 567px) {
  .modal {
    &-main {
      max-width: 325px;
      &__content {
        padding: 60px 32px 55px;
      }
      &__title {
        font-size: 24px;
        line-height: 29px;
        color: $black;
      }
      &__text {
        display: none;
      }
      &__btn {
        width: 127px;
      }
    }
  }
}
</style>

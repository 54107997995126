<template>
  <header class="header">
    <div class="container">
      <div class="header__row">
        <div class="header__col-left">
          <a href="/" class="header__logo">
            <img src="~@/assets/img/teva-logo.svg" alt="" />
          </a>
          <div class="header__game-detail">
            <div class="header__game-name">
              Гра «Тева-ребуси»
            </div>
            <div class="header__game-info">
              Назви препаратів — у простих речах. Спробуйте відгадати!
            </div>
          </div>
        </div>
        <div class="header__col-right">
          <div
            class="header__how-play main-button main-button--blue"
            :class="{
              'header__how-play--disabled':
                getIsWelcomeModal || getIsHowToStartModal || getIsHowToPlayModal
            }"
            @click="showWelcomeModal()"
          >
            <span>Як грати?</span>
          </div>
          <div
            class="header__game-reload main-button main-button--blue main-button--reload"
            @click="showReloadGameModal()"
          >
            <span>Почати спочатку</span>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
/* eslint-disable no-undef */
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Header",
  computed: {
    ...mapGetters([
      "getIsWelcomeModal",
      "getIsHowToStartModal",
      "getIsHowToPlayModal"
    ])
  },
  methods: {
    ...mapMutations([
      "setReloadGameModal",
      "setIsWelcomeModal",
      "setDrugModal",
      "setQuestionModal",
      "setIsHowToStartModal",
      "setIsHowToPlayModal"
    ]),
    showReloadGameModal() {
      this.setDrugModal(false);
      this.setQuestionModal(false);
      this.setIsWelcomeModal(false);
      this.setIsHowToStartModal(false);
      this.setIsHowToPlayModal(false);
      this.setReloadGameModal(true);
      gtag("event", "click", { event_category: "game_start_again " });
    },
    showWelcomeModal() {
      this.setDrugModal(false);
      this.setQuestionModal(false);
      this.setReloadGameModal(false);
      this.setIsWelcomeModal(true);
      gtag("event", "click", { event_category: "onboading_header_btn" });
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";
.header {
  position: relative;
  padding: 32px 0 46px;
  background: #fff;
  box-shadow: 0px 4px 49px rgba(0, 0, 0, 0.09);
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  &__col {
    &-left {
      display: flex;
      flex-wrap: wrap;
      padding: 0 15px;
      flex-basis: 60%;
      max-width: 60%;
    }
    &-right {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      padding: 0 15px;
      flex-basis: 40%;
      max-width: 40%;
    }
  }
  &__logo {
    display: inline-block;
    margin-right: 51px;
    padding-top: 4px;
  }
  &__game-detail {
    display: flex;
    flex-wrap: wrap;
  }
  &__game-name {
    margin-right: 90px;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: $green;
  }
  &__game-info {
    max-width: 330px;
    font-size: 16px;
    line-height: 20px;
    z-index: 100;
  }
  &__how-play {
    z-index: 100;
    &--disabled {
      pointer-events: none;
    }
  }
  &__game-reload {
    margin-left: 30px;
    z-index: 100;
  }
}
@media screen and (max-width: 1770px) {
  .header {
    &__game {
      &-name {
        margin-right: 40px;
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .header {
    padding: 20px 0 20px;
    &__row {
      align-items: center;
    }
    &__game {
      &-name {
        margin-right: 0;
        font-size: 22px;
        line-height: 29px;
      }
      &-detail {
        flex-direction: column;
        flex-grow: 1;
      }
      &-info {
        max-width: 100%;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .container {
    padding: 0 16px;
  }
  .header {
    padding: 8px 0 8px;
    z-index: 98;
    &__col {
      &-left {
        flex-basis: 80%;
        max-width: 80%;
      }
      &-right {
        flex-basis: 20%;
        max-width: 20%;
      }
    }
    &__logo {
      flex-basis: 70px;
      max-width: 70px;
      margin-right: 20px;
    }
    &__game {
      &-detail {
        flex-basis: 80%;
      }
      &-name {
        font-size: 16px;
        line-height: 20px;
      }
      &-info {
        font-size: 12px;
        line-height: 15px;
      }
    }
    &__how-play {
      background-image: url("~@/assets/img/question-icon.svg");
      background-repeat: no-repeat;
      background-position: center;

      span {
        display: none;
      }
    }
    &__game-reload {
      margin-left: 15px;
    }
  }
}
@media screen and (max-width: 567px) {
  .header {
    padding: 17px 0 20px;
    text-align: center;
    &__col {
      &-left {
        display: block;
        flex-basis: 100%;
        max-width: 100%;
      }
      &-right {
        display: none;
      }
    }
    &__logo {
      max-width: 144px;
      margin: 0 auto 14px;
    }
    &__game-name {
      margin-bottom: 11px;
    }
  }
}
</style>

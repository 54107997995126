<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__row">
        <div class="footer__col-left">
          <a href="/" class="footer__logo-mobile">
            <img src="~@/assets/img/teva-logo.svg" alt="" />
          </a>
          <div class="footer__copyright">
            <p>
              ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ «ТЕВА УКРАЇНА» <br />
              Ідентифікаційний код юридичної особи: 34770471
            </p>
            <a
              target="_blank"
              href="https://www.teva.ua/general-pages/Terms-of-Use/"
            >
              Політика конфіденційності</a
            >
          </div>
        </div>
        <div class="footer__col-middle">
          <div class="footer__info">
            <p>
              Україна, 02152, місто Київ, проспект Павла Тичини, 1-В, поверх 9,
              БЦ «Silver Breeze»
            </p>
            <p>
              Електронна адреса:
              <a href="mailto:Consent@teva.ua">Consent@teva.ua</a>
            </p>
            <p>Телефон: +380 44 594 70 80</p>
          </div>
        </div>
        <div class="footer__col-right">
          <a href="/" class="footer__logo">
            <img src="~@/assets/img/teva-logo.svg" alt="" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";
.footer {
  padding: 28px 0;
  background: #ffffff;
  box-shadow: 0px 4px 49px rgba(0, 0, 0, 0.09);
  &__row {
    display: flex;
    flex-wrap: wrap;
    max-width: 1110px;
    margin: auto;
  }
  &__col {
    &-left {
      flex-basis: 44%;
      max-width: 44%;
    }
    &-middle {
      flex-basis: 40%;
      max-width: 40%;
    }
    &-right {
      flex-basis: 16%;
      max-width: 16%;
    }
  }
  &__copyright {
    p {
      margin-bottom: 2px;
      font-weight: 300;
      font-size: 10px;
      line-height: 12px;
    }
    a {
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      color: $blue;
    }
  }
  &__info {
    padding-left: 5px;
    p {
      margin-bottom: 4px;
      font-weight: 300;
      font-size: 10px;
      line-height: 12px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    a {
      font-weight: 600;
      color: $blue;
    }
  }
  &__logo {
    display: block;
    padding-top: 4px;
    padding-right: 3px;
    text-align: right;
  }
  &__logo-mobile {
    display: none;
  }
}
@media screen and (max-width: 1199px) {
  .container {
    padding: 0 16px;
  }
  .footer {
    padding: 16px 0;
    &__col {
      &-left {
        flex-basis: 42%;
        max-width: 42%;
      }
      &-middle {
        flex-basis: 42%;
        max-width: 42%;
      }
    }
    &__copyright {
      p {
        margin-bottom: -3px;
        font-size: 8px;
        line-height: 10px;
      }
      a {
        font-size: 8px;
        line-height: 10px;
      }
    }
    &__info {
      padding-right: 60px;
      p {
        margin-bottom: -1px;
        font-size: 8px;
        line-height: 10px;
      }
    }
    &__logo {
      max-width: 90px;
      float: right;
      padding-top: 0;
      margin-right: 0;
    }
  }
}
@media screen and (max-width: 567px) {
  .footer {
    padding: 29px 0 31px;
    text-align: center;
    &__col {
      &-left,
      &-middle,
      &-right {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
    &__logo {
      display: none;
    }
    &__logo-mobile {
      display: block;
      max-width: 90px;
      margin: 0 auto 21px;
    }
    &__copyright {
      margin-bottom: 8px;
    }
    &__info {
      padding-right: 0;
    }
  }
}
</style>

<template>
  <div class="modal">
    <div class="modal-main">
      <div class="modal-main__content">
        <div class="modal-main__text">
          Перед вами 5 назв препаратів. Оберіть той, що, на вашу думку,
          підходить під ребус, та натисніть на його назву. Якщо не вгадали — не
          біда, просто спробуйте ще раз!
        </div>
        <div
          class="modal-main__btn"
          @click="closeModal()"
          onClick="gtag('event', 'click', { 'event_category': ' onboading_step_3'}) "
        >
          Зрозуміло
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "HowToStartModal",
  methods: {
    ...mapMutations(["setIsHowToPlayModal"]),
    closeModal() {
      this.setIsHowToPlayModal(false);
      localStorage.setItem("onboarding", false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";
.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 98;

  &-main {
    position: absolute;
    width: 100%;
    max-width: 640px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    box-shadow: 0px 7px 61px rgba(0, 0, 0, 0.17);

    &__content {
      padding: 33px 34px 47px 283px;
      background: url("~@/assets/img/how-to-play.svg") no-repeat;
      background-position: 65px center;
    }

    &__text {
      margin-bottom: 22px;
      font-weight: 300;
      font-size: 20px;
      line-height: 25px;
    }
    &__btn {
      width: 210px;
      height: 40px;
      background-color: $blue;
      border-radius: 40px;
      font-weight: 600;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      transition: all 0.5s;
      &:hover {
        background-color: $purple;
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .modal {
    &-main {
      max-width: 425px;
      &__content {
        padding: 30px 20px 30px 160px;
        background-position: 15px 31px;
        background-size: 130px;
      }
      &__title {
        font-size: 22px;
        line-height: 25px;
      }
      &__text {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
}
</style>

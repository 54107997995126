var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.closeModal()}}},[_c('div',{staticClass:"modal-main"},[_c('div',{staticClass:"modal-main__close",on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("×")]),_c('div',{staticClass:"modal-main__row"},[_c('div',{staticClass:"modal-main__col-left"},[_c('div',{staticClass:"modal-main__image"},[_c('img',{attrs:{"src":require(("@/assets/img/questions/" + (_vm.getCurrentQuestion.image))),"alt":""}})])]),_c('div',{staticClass:"modal-main__col-right"},[_c('div',{staticClass:"modal-main__detail"},[_c('div',{staticClass:"modal-main__title"},[_vm._v("Я думаю, що це:")]),_c('div',{staticClass:"modal-main__messages"},[(_vm.isSelectedAnswer && !_vm.selectedAnswer.isCorrect)?_c('div',{staticClass:"modal-main__message modal-main__message--incorrect"},[_vm._v(" Неправильно! Спробуйте ще раз ")]):_vm._e(),(_vm.isSelectedAnswer && _vm.selectedAnswer.isCorrect)?_c('div',{staticClass:"modal-main__message modal-main__message--correct"},[_vm._v(" Правильно! Це — "+_vm._s(_vm.selectedAnswer.name)+" ")]):_vm._e()]),_c('div',{staticClass:"modal-main__answers"},_vm._l((_vm.getAnswers),function(answer,key){return _c('div',{key:key,staticClass:"modal-main__answer",class:{
                'modal-main__answer--correct':
                  _vm.isSelectedAnswer &&
                  answer.isSelected &&
                  _vm.selectedAnswer.isCorrect,
                'modal-main__answer--incorrect':
                  _vm.isSelectedAnswer &&
                  answer.isSelected &&
                  !_vm.selectedAnswer.isCorrect
              },on:{"click":function($event){return _vm.selectAnswer(answer, key)}}},[_vm._v(" "+_vm._s(answer.name)+" ")])}),0)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="game">
    <div class="container">
      <div class="game-desktop">
        <GameProgress />
        <div class="game-image">
          <div
            class="game-image__group"
            :class="{ 'default-cursor': zoom === 1 }"
            ref="game"
          >
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 1565 952"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <rect y="1" width="1565" height="951" fill="url(#pattern0)" />
              <template v-for="(question, key) in getQuestions">
                <g :key="key">
                  <circle
                    :cx="question.cx"
                    :cy="question.cy"
                    r="7.5"
                    :fill="question.hasCorrectAnswer ? '#00A03B' : '#FB3449'"
                  ></circle>
                  <circle
                    @click="selectCurrentQuestion(key, question)"
                    @mouseenter="showQuestionInfoModal(key + 1)"
                    @mouseleave="hideQuestionInfoModal(key + 1)"
                    class="game-dots__item"
                    :cx="question.cx"
                    :cy="question.cy"
                    fill="transparent"
                    r="22"
                  ></circle>
                </g>
                <foreignObject
                  :data-info-modal="key + 1"
                  v-if="!question.hasCorrectAnswer"
                  :key="`red-info-${key}`"
                  :x="question.cx - 125 / 2"
                  :y="question.cy - 75"
                  width="125"
                  height="70"
                >
                  <div
                    xmlns="http://www.w3.org/1999/xhtml"
                    class="game-dots__info game-dots__info--red"
                  >
                    Натиснiть, щоб
                    <br />
                    <strong>відгадати</strong>
                  </div>
                </foreignObject>
                <foreignObject
                  :data-info-modal="key + 1"
                  v-if="question.hasCorrectAnswer"
                  :key="`green-info-${key}`"
                  :x="question.cx - 175 / 2"
                  :y="question.cy - 75"
                  width="175"
                  height="60"
                >
                  <div
                    xmlns="http://www.w3.org/1999/xhtml"
                    class="game-dots__info game-dots__info--green"
                  >
                    Відгадано. Натисніть, щоб
                    <br />
                    <strong>Переглянути</strong>
                  </div>
                </foreignObject>
              </template>
              <defs>
                <pattern
                  id="pattern0"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1"
                >
                  <use
                    xlink:href="#image0"
                    transform="translate(0 -0.000262881) scale(0.000319489 0.000525762)"
                  />
                </pattern>
                <image
                  id="image0"
                  width="3130"
                  height="1903"
                  xlink:href="~@/assets/img/game.jpg"
                />
              </defs>
            </svg>
          </div>
          <div class="game-pan">
            <div
              class="game-pan__btn game-pan__btn--top"
              @click="panTop()"
            ></div>
            <div
              class="game-pan__btn game-pan__btn--right"
              @click="panRight()"
            ></div>
            <div
              class="game-pan__btn game-pan__btn--bottom"
              @click="panBottom()"
            ></div>
            <div
              class="game-pan__btn game-pan__btn--left"
              @click="panLeft()"
            ></div>
          </div>
          <div class="game-zoom">
            <div
              class="game-zoom__btn game-zoom__btn-in"
              @click="gameZoomIn()"
            ></div>
            <div
              class="game-zoom__btn game-zoom__btn-out"
              @click="gameZoomOut()"
            ></div>
          </div>
        </div>
      </div>
      <div class="game-mobile">
        <div class="game-mobile__title">
          Будь ласка, поверніть ваш пристрій для відображення гри
        </div>
        <div class="game-mobile__icon">
          <img src="~@/assets/img/rotate-phone.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import GameProgress from "@/components/game/GameProgress.vue";
import Panzoom from "@panzoom/panzoom";
export default {
  name: "Game",
  data() {
    return {
      panzoom: null,
      zoom: 1
    };
  },
  components: {
    GameProgress
  },
  computed: {
    ...mapGetters([
      "getQuestions",
      "getCurrentQuestion",
      "getGameProgress",
      "getIsDrugModal"
    ]),
    gameState() {
      return {
        progress: this.getGameProgress,
        isDrugModal: this.getIsDrugModal
      };
    }
  },
  watch: {
    getGameProgress(value) {
      // eslint-disable-next-line no-undef
      gtag("event", "click", { event_category: `true_answear_${value}` });
    },
    gameState(value) {
      if (
        value.progress === 30 &&
        !value.isDrugModal &&
        localStorage.getItem("gameOwer") === null
      ) {
        this.setIsGameOwerModal(true);
      }
    }
  },
  methods: {
    ...mapMutations([
      "selectQuestion",
      "setQuestionModal",
      "setDrugModal",
      "updateGameData",
      "setOnlySpecialistModal",
      "setIsWelcomeModal",
      "setIsGameOwerModal"
    ]),
    selectCurrentQuestion(key, question) {
      this.selectQuestion(key);
      if (this.getCurrentQuestion.hasCorrectAnswer) {
        this.setDrugModal(true);
        // eslint-disable-next-line no-undef
        gtag("event", "click", { event_category: `green_dot_${question.id}` });
      } else {
        this.setQuestionModal(true);
        // eslint-disable-next-line no-undef
        gtag("event", "click", { event_category: `red_dot_${question.id}` });
      }
    },
    getOffsetRect(elem) {
      var box = elem.getBoundingClientRect();
      var body = document.body;
      var docElem = document.documentElement;
      var scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop;
      var scrollLeft =
        window.pageXOffset || docElem.scrollLeft || body.scrollLeft;
      var clientTop = docElem.clientTop || body.clientTop || 0;
      var clientLeft = docElem.clientLeft || body.clientLeft || 0;
      var top = box.top + scrollTop - clientTop;
      var left = box.left + scrollLeft - clientLeft;

      return {
        top: Math.round(top),
        left: Math.round(left)
      };
    },

    showQuestionInfoModal(key) {
      let infoModal = document.querySelector("[data-info-modal='" + key + "']")
        .childNodes[0];
      infoModal.style.visibility = "visible";
    },
    hideQuestionInfoModal(key) {
      let infoModal = document.querySelector("[data-info-modal='" + key + "']")
        .childNodes[0];
      infoModal.style.visibility = "hidden";
    },
    gameZoomIn() {
      if (this.zoom < 2) {
        this.zoom += 0.1;
        this.panzoom.zoom(this.zoom, { animate: true });
      }
    },
    gameZoomOut() {
      if (this.zoom > 1) {
        this.zoom -= 0.1;
        this.panzoom.zoom(this.zoom, { animate: true });
      }
    },
    panTop() {
      this.panzoom.pan(0, 20, { relative: true });
    },
    panRight() {
      this.panzoom.pan(-20, 0, { relative: true });
    },
    panBottom() {
      this.panzoom.pan(0, -20, { relative: true });
    },
    panLeft() {
      this.panzoom.pan(20, 0, { relative: true });
    }
  },
  created() {
    this.updateGameData();
  },
  mounted() {
    this.panzoom = Panzoom(this.$refs.game, {
      contain: "outside",
      startScale: 1,
      maxScale: 2,
      touchAction: "pan-y"
    });

    window.addEventListener("orientationchange", () => {
      window.location.reload();
    });

    if (localStorage.getItem("onlySpecialistModal") === null) {
      this.setOnlySpecialistModal(true);
    }

    if (localStorage.getItem("onboarding") === null) {
      if (window.orientation === 90 || window.orientation === -90) {
        this.setIsWelcomeModal(true);
      } else {
        this.setIsWelcomeModal(false);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";
.game {
  &-desctop {
    position: relative;
  }
  &-mobile {
    display: none;
    padding: 50px 0 50px;
    &__title {
      margin-bottom: 30px;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: $black;
    }
    &__icon {
      text-align: center;
    }
  }
  &-image {
    position: relative;
    overflow: hidden;
    &__group {
      position: relative;
      &.default-cursor {
        cursor: default !important;
      }
      svg {
        display: block;
        width: 100%;
        max-width: 100%;
      }
    }
  }
  &-dots {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &__item {
      position: relative;
      cursor: pointer;
    }
    &__info {
      background: #fff;
      padding: 7px 10px 5px;
      border-radius: 5px;
      visibility: hidden;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #000000;
      cursor: default;

      &:after {
        content: "";
        position: absolute;
        left: 50%;
        width: 13px;
        height: 13px;
        background: #fff;
        transform: translateX(-50%) rotate(45deg);
      }
      strong {
        display: block;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }
      &--red {
        width: 125px;
        border: 2px solid $red;
        &:after {
          bottom: 13px;
          border-right: 2px solid $red;
          border-bottom: 2px solid $red;
        }
      }
      &--green {
        width: 175px;
        border: 2px solid $green;
        &:after {
          bottom: 3px;
          border-right: 2px solid $green;
          border-bottom: 2px solid $green;
        }
      }
    }
  }
  &-zoom {
    position: absolute;
    bottom: 8px;
    right: 8px;
    &__btn {
      width: 24px;
      height: 24px;
      margin-bottom: 8px;
      background-color: #ffffff;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 19px;
      cursor: pointer;
      &-in {
        background-image: url("~@/assets/img/plus-purple-icon.svg");
        &:hover {
          background-image: url("~@/assets/img/plus-white-icon.svg");
          background-color: $purple;
        }
      }
      &-out {
        background-image: url("~@/assets/img/minus-purple-icon.svg");
        &:hover {
          background-image: url("~@/assets/img/minus-white-icon.svg");
          background-color: $purple;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &-pan {
    position: absolute;
    left: 8px;
    bottom: 8px;
    display: none;
    &__btn {
      position: absolute;
      width: 24px;
      height: 24px;
      background-color: #ffffff;
      background-repeat: no-repeat;
      border-radius: 13px;
      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        left: 0;
        border-width: 0 7px 8px 7px;
        border-color: transparent transparent $purple transparent;
        border-style: solid;
      }
      &:hover {
        background-color: $purple;
        &:before {
          border-color: transparent transparent #fff transparent;
        }
      }
      &--top {
        top: -55px;
        left: 31px;
        &:before {
          top: 6px;
          left: 5px;
        }
      }
      &--right {
        top: -24px;
        left: 65px;
        &:before {
          top: 8px;
          left: 6px;
          transform: rotate(90deg);
        }
      }
      &--bottom {
        top: -24px;
        left: 31px;
        &:before {
          top: 9px;
          left: 5px;
          transform: rotate(180deg);
        }
      }
      &--left {
        top: -24px;
        left: 0;
        &:before {
          top: 8px;
          left: 3px;
          transform: rotate(-90deg);
        }
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .container {
    padding: 0 16px;
  }
  .game {
    padding: 14px 0 20px;
    &-pan {
      display: block;
    }
    &-image {
      border-radius: 8px;
    }
  }
}
@media screen and (max-width: 567px) {
  .game {
    &-desktop {
      display: none;
    }
    &-mobile {
      display: block;
    }
  }
}
</style>

<template>
  <div class="modal" @click.self="closeModal()">
    <div class="modal-main">
      <div class="modal-main__content">
        <div class="modal-main__title">
          Впевнені, що хочете <br />
          почати гру спочатку?
        </div>
        <div class="modal-main__subtitle">
          Ваші відповіді буде втрачено
        </div>
        <div class="modal-main__buttons">
          <div
            class="modal-main__btn modal-main__btn--yes"
            @click="closeModal(), reloadGameData()"
            onClick="gtag('event', 'click', { 'event_category': ' game_start_again_true'}) "
          >
            Так
          </div>
          <div
            class="modal-main__btn modal-main__btn--no"
            @click="closeModal()"
            onClick="gtag('event', 'click', { 'event_category': 'game_start_again_false'})"
          >
            Ні
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "ReloadGameModal",
  methods: {
    ...mapMutations(["setReloadGameModal", "reloadGame"]),
    reloadGameData() {
      this.reloadGame();
    },
    closeModal() {
      this.setReloadGameModal(false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";
.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 90, 133, 0.6);
  z-index: 98;

  &-main {
    position: absolute;
    width: 100%;
    max-width: 615px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    box-shadow: 0px 7px 61px rgba(0, 0, 0, 0.17);

    &__content {
      padding: 41px 99px 59px;
    }
    &__title {
      margin-bottom: 18px;
      font-weight: 600;
      font-size: 32px;
      line-height: 39px;
      text-align: center;
      color: $green;
    }
    &__subtitle {
      margin-bottom: 45px;
      font-weight: 300;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }
    &__buttons {
      display: flex;
      justify-content: center;
    }
    &__btn {
      width: 130px;
      height: 42px;
      margin: 0 15px;
      border: 2px solid $blue;
      border-radius: 100px;
      font-weight: 600;
      font-size: 15px;
      line-height: 40px;
      text-align: center;
      color: $blue;
      transition: all 0.5s;
      cursor: pointer;
      &--yes {
        &:hover {
          background-color: $purple;
          border-color: $purple;
          color: #fff;
        }
      }
      &--no {
        background-color: $blue;
        color: #fff;
        &:hover {
          background-color: $purple;
          border-color: $purple;
        }
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .modal {
    &-main {
      max-width: 375px;
      &__content {
        padding: 30px 20px 30px;
      }
      &__title {
        font-size: 22px;
        line-height: 25px;
      }
      &__subtitle {
        margin-bottom: 35px;
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
}
</style>

<template>
  <div class="modal" @click.self="closeModal()">
    <div class="modal-main">
      <div class="modal-main__close" @click="closeModal()">&times;</div>
      <div class="modal-main__content">
        <div class="modal-main__title">
          Вітаємо справжнього <br />
          експерта Тева!
        </div>
        <div class="modal-main__subtitle">
          Ви відгадали усі назви!
        </div>
        <div class="modal-main__text">
          Переходьте на сайт
          <a target="_blank" href="https://cbg.teva.ua">cbg.teva.ua</a> та
          дізнайтеся ще більше про препарати Лінійки бренду Тева
        </div>
        <a
          href="https://cbg.teva.ua/ukr"
          target="_blank"
          class="modal-main__btn"
          onClick="gtag('event', 'click', { 'event_category': 'go_check_cbg_drugs'})"
        >
          Переглянути всi препарати <br />
          Лінійки бренду Тева
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "GameOwerModal",
  methods: {
    ...mapMutations(["setIsGameOwerModal"]),
    closeModal() {
      this.setIsGameOwerModal(false);
      localStorage.setItem("gameOwer", true);
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    gtag("event", "onLoad", { event_category: "game_over" });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";
.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 90, 133, 0.6);
  z-index: 98;

  &-main {
    position: absolute;
    width: 100%;
    max-width: 615px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    box-shadow: 0px 7px 61px rgba(0, 0, 0, 0.17);
    &__close {
      position: absolute;
      right: 15px;
      top: 15px;
      font-size: 40px;
      line-height: 20px;
      color: $gray;
      cursor: pointer;
      &:hover {
        color: $purple;
      }
    }
    &__content {
      padding: 41px 52px 48px;
    }
    &__title {
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 32px;
      line-height: 39px;
      text-align: center;
      color: $green;
    }
    &__subtitle {
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }
    &__text {
      margin-bottom: 27px;
      font-weight: 300;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      a {
        color: $green;
      }
    }

    &__btn {
      display: block;
      width: 320px;
      height: 46px;
      margin: auto;
      padding: 4px;
      background: $green;
      border-radius: 100px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-decoration: none;
      text-align: center;
      color: #fff;
      transition: all 0.5s;
      cursor: pointer;
      &:hover {
        background: $purple;
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .modal {
    &-main {
      max-width: 375px;
      &__content {
        padding: 30px 20px 30px;
      }
      &__title {
        font-size: 22px;
        line-height: 25px;
      }
      &__subtitle {
        font-size: 18px;
        line-height: 20px;
      }
      &__text {
        font-size: 16px;
        line-height: 18px;
      }
      &__btn {
        margin: 0 auto 22px;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
</style>

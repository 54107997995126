<template>
  <div class="game-progress">
    <div class="game-progress__content">
      <div class="game-progress__title">Відгадано</div>
      <div class="game-progress__value">
        {{ getGameProgress }}<span>/{{ getQuestions.length }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "GameProgress",
  computed: {
    ...mapGetters(["getGameProgress", "getQuestions"])
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";
.game-progress {
  position: absolute;
  top: -240px;
  right: -58px;
  width: 375px;
  height: 375px;
  border-radius: 50%;
  background: linear-gradient(89.95deg, #00a03b 0.05%, #afcb37 99.96%);
  z-index: 99;
  &__content {
    position: absolute;
    bottom: 78px;
    left: 66px;
  }
  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  }
  &__value {
    font-weight: 750;
    font-size: 27px;
    line-height: 33px;
    color: #fff;
    span {
      color: $green-light;
    }
  }
}
@media screen and (max-width: 1550px) {
  .game-progress {
    right: -150px;
  }
}
@media screen and (max-width: 1199px) {
  .game-progress {
    top: -103px;
    right: -72px;
    width: 170px;
    height: 170px;
    z-index: 1;
    &__content {
      bottom: 26px;
      left: 40px;
    }
    &__title {
      font-size: 8.16802px;
      line-height: 10px;
    }
    &__value {
      font-size: 13.7835px;
      line-height: 17px;
    }
  }
}
</style>

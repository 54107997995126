<template>
  <div class="modal" @click.self="closeModal()">
    <div class="modal-main">
      <div class="modal-main__close" @click="closeModal()">&times;</div>
      <div class="modal-main__row">
        <div class="modal-main__col-left">
          <div class="modal-main__image">
            <img
              :src="
                require(`@/assets/img/questions/${getCurrentQuestion.image}`)
              "
              alt=""
            />
          </div>
        </div>
        <div class="modal-main__col-right">
          <div class="modal-main__detail">
            <div class="modal-main__title">Я думаю, що це:</div>
            <div class="modal-main__messages">
              <div
                v-if="isSelectedAnswer && !selectedAnswer.isCorrect"
                class="modal-main__message modal-main__message--incorrect"
              >
                Неправильно! Спробуйте ще раз
              </div>
              <div
                v-if="isSelectedAnswer && selectedAnswer.isCorrect"
                class="modal-main__message modal-main__message--correct"
              >
                Правильно! Це — {{ selectedAnswer.name }}
              </div>
            </div>
            <div class="modal-main__answers">
              <div
                v-for="(answer, key) in getAnswers"
                :key="key"
                @click="selectAnswer(answer, key)"
                class="modal-main__answer"
                :class="{
                  'modal-main__answer--correct':
                    isSelectedAnswer &&
                    answer.isSelected &&
                    selectedAnswer.isCorrect,
                  'modal-main__answer--incorrect':
                    isSelectedAnswer &&
                    answer.isSelected &&
                    !selectedAnswer.isCorrect
                }"
              >
                {{ answer.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "QuestionModal",
  data() {
    return {
      isSelectedAnswer: false,
      selectedAnswer: null
    };
  },
  computed: {
    ...mapGetters(["getDrugs", "getCurrentQuestion"]),
    getAnswers() {
      var answers = [];
      this.getCurrentQuestion.answers.forEach(answer => {
        this.getDrugs.forEach(drug => {
          if (drug.id === answer.drugId) {
            drug.isCorrect = answer.isCorrect;
            answers.push(drug);
          }
        });
      });
      return answers;
    }
  },
  methods: {
    ...mapMutations([
      "setQuestionModal",
      "setDrugModal",
      "setQuestionAnswer",
      "saveGame"
    ]),
    selectAnswer(selectedAnswer, selectedAnswerKey) {
      this.isSelectedAnswer = true;
      this.selectedAnswer = selectedAnswer;
      this.getAnswers.forEach((answer, answerKey) => {
        if (selectedAnswerKey === answerKey) {
          answer.isSelected = true;
        } else {
          answer.isSelected = false;
        }
      });
      if (this.selectedAnswer.isCorrect) {
        setTimeout(() => {
          this.setQuestionAnswer();
          this.setQuestionModal(false);
          this.setDrugModal(true);
          this.saveGame();
        }, 200);
      }

      // eslint-disable-next-line no-undef
      gtag("event", "click", {
        event_category: `q${
          this.getCurrentQuestion.id
        }_choice_${selectedAnswerKey + 1}`
      });
    },
    closeModal() {
      this.setQuestionModal(false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 90, 133, 0.6);
  z-index: 100;
  &-main {
    position: absolute;
    width: 100%;
    max-width: 1080px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 0 34px;
    background: #ffffff;
    box-shadow: 0px 7px 61px rgba(0, 0, 0, 0.17);
    &__row {
      display: flex;
      flex-wrap: wrap;
    }
    &__col {
      &-left {
        flex-basis: 54%;
        max-width: 54%;
      }
      &-right {
        flex-basis: 46%;
        max-width: 46%;
      }
    }
    &__image {
      img {
        display: block;
        max-width: 395px;
        border-radius: 50%;
        margin: 5px auto 0;
      }
    }
    &__detail {
      max-width: 355px;
    }
    &__close {
      position: absolute;
      right: 15px;
      top: 15px;
      font-size: 40px;
      line-height: 20px;
      color: $gray;
      cursor: pointer;
      &:hover {
        color: $purple;
      }
    }
    &__title {
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #141414;
    }
    &__messages {
      margin-bottom: 17px;
      min-height: 58px;
    }
    &__message {
      padding: 17px 5px 20px 62px;
      background-repeat: no-repeat;
      background-position: 13px center;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: #ffffff;

      &--incorrect {
        background-color: $red;
        background-image: url("~@/assets/img/error-answer-icon.svg");
      }
      &--correct {
        background-color: $green;
        background-image: url("~@/assets/img/success-answer-icon.svg");
      }
    }
    &__answer {
      margin-bottom: 20px;
      border: 2px solid $blue;
      border-radius: 40px;
      font-weight: 600;
      font-size: 16px;
      line-height: 36px;
      text-align: center;
      color: $blue;
      cursor: pointer;
      &--correct {
        background-color: $green;
        border-color: $green;
        color: #fff;
        pointer-events: none;
      }
      &--incorrect {
        background-color: $red;
        border-color: $red;
        color: #fff;
        pointer-events: none;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .modal {
    position: fixed;
    z-index: 100;
    &-main {
      max-width: 650px;
      padding: 10px 0 34px;
      &__col {
        &-left {
          flex-basis: 50%;
          max-width: 50%;
        }
        &-right {
          flex-basis: 50%;
          max-width: 50%;
        }
      }
      &__close {
        right: 10px;
        top: 10px;
      }
      &__title {
        margin-bottom: 12px;
        font-size: 12px;
        line-height: 15px;
      }
      &__image {
        padding-top: 20px;
        img {
          max-width: 255px;
        }
      }
      &__detail {
        max-width: 255px;
        padding-left: 10px;
      }
      &__messages {
        min-height: 40px;
        margin-bottom: 10px;
      }
      &__message {
        padding: 10px 5px 10px 40px;
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: 10px center;
        font-weight: 600;
        font-size: 10px;
        line-height: 20px;
      }
      &__answer {
        margin-bottom: 12px;
        font-size: 12px;
        line-height: 25px;
      }
    }
  }
}
</style>

<template>
  <div class="modal" @click.self="closeModal()">
    <div class="modal-main">
      <div class="modal-main__close" @click="closeModal()">&times;</div>
      <div class="modal-main__row">
        <div class="modal-main__col-left">
          <div class="modal-main__image">
            <img
              :src="require(`@/assets/img/drugs/${getCurrentDrug.image}`)"
              alt=""
            />
          </div>
        </div>
        <div class="modal-main__col-right">
          <div class="modal-main__detail">
            <div class="modal-main__message modal-main__message--correct">
              Правильно! Це — {{ getCurrentDrug.name }}
            </div>
            <div class="modal-main__title">{{ getCurrentDrug.name }} —</div>
            <div class="modal-main__text">{{ getCurrentDrug.description }}</div>
            <div class="modal-main__btn" @click="closeModal()">
              Продовжити
            </div>
            <a
              href="https://cbg.teva.ua/ukr"
              target="_blank"
              class="modal-main__link"
              onClick="gtag('event', 'click', { 'event_category': ' check_cbg_drugs'})"
            >
              Переглянути всi препарати Лінійки бренду Тева
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "DrugModal",
  data() {
    return {
      drug: null
    };
  },
  computed: {
    ...mapGetters(["getDrugs", "getCurrentQuestion", "getGameProgress"]),
    getCurrentDrug() {
      var currentDrug = null;
      this.getCurrentQuestion.answers.forEach(answer => {
        if (answer.isCorrect) {
          this.getDrugs.forEach(drug => {
            if (answer.drugId === drug.id) {
              currentDrug = drug;
            }
          });
        }
      });
      return currentDrug;
    }
  },
  methods: {
    ...mapMutations(["setDrugModal"]),
    closeModal() {
      this.setDrugModal(false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 90, 133, 0.6);
  z-index: 100;

  &-main {
    position: absolute;
    width: 100%;
    max-width: 1080px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    box-shadow: 0px 7px 61px rgba(0, 0, 0, 0.17);
    &__row {
      display: flex;
      flex-wrap: wrap;
    }
    &__col {
      &-left {
        flex-basis: 42%;
        max-width: 42%;
      }
      &-right {
        flex-basis: 58%;
        max-width: 58%;
      }
    }

    &__close {
      position: absolute;
      right: 15px;
      top: 15px;
      font-size: 40px;
      line-height: 20px;
      color: $gray;
      cursor: pointer;
      &:hover {
        color: $purple;
      }
    }
    &__image {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 30px;
      background: linear-gradient(180deg, #e4f5e9 18.97%, #f2f3f3 89.12%);
      img {
        display: block;
        max-width: 100%;
      }
    }
    &__detail {
      padding: 63px 75px 48px 129px;
    }
    &__message {
      margin-bottom: 47px;
      padding: 17px 5px 20px 62px;
      background-repeat: no-repeat;
      background-position: 13px center;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      &--correct {
        background-color: $green;
        background-image: url("~@/assets/img/success-answer-icon.svg");
      }
    }
    &__title {
      margin-bottom: 16px;
      font-weight: 450;
      font-size: 35px;
      line-height: 43px;
      color: $green;
    }
    &__text {
      margin-bottom: 52px;
      font-weight: 300;
      font-size: 20px;
      line-height: 25px;
    }
    &__btn {
      display: block;
      padding: 10px 12px 11px;
      margin-bottom: 20px;
      background-color: $blue;
      border-radius: 64px;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-decoration: none;
      text-align: center;
      color: #fff;
      cursor: pointer;
      transition: background-color 0.5s;
      &:hover {
        background-color: $purple;
      }
      br {
        display: none;
      }
    }
    &__link {
      display: block;
      font-size: 12px;
      line-height: 17px;
      text-decoration: underline;
      text-align: center;
      color: $gray-dark;
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 1199px) {
  .modal {
    position: fixed;
    z-index: 100;
    &-main {
      max-width: 650px;
      &__col {
        &-left {
          flex-basis: 44%;
          max-width: 44%;
        }
        &-right {
          flex-basis: 56%;
          max-width: 56%;
        }
      }
      &__close {
        right: 10px;
        top: 10px;
      }
      &__title {
        margin-bottom: 12px;
        font-size: 12px;
        line-height: 15px;
      }
      &__detail {
        padding: 45px 15px 25px 25px;
      }
      &__message {
        margin-bottom: 15px;
        padding: 10px 5px 10px 40px;
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: 10px center;
        font-weight: 600;
        font-size: 10px;
        line-height: 20px;
      }
      &__title {
        margin-bottom: 10px;
        font-size: 23px;
        line-height: 29px;
      }
      &__text {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 15px;
      }
    }
  }
}
</style>

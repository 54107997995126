import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    gameProgress: 0,
    currensQuestion: null,
    isQuestionModal: false,
    isDrugModal: false,
    isReloadGameModal: false,
    isOnlySpecialistModal: false,
    isNotSpecialistModal: false,
    isWelcomeModal: false,
    isHowToStartModal: false,
    isHowToPlayModal: false,
    isGameOwerModal: false,
    drugs: [
      {
        id: 1,
        image: "1.png",
        name: "Амброксол-Тева",
        description:
          "Застосовується при кашлі та застудних захворюваннях. Муколітичний засіб"
      },
      {
        id: 2,
        image: "2.png",
        name: "Амлодипін-Тева",
        description:
          "Селективний антагоніст кальцію. Препарат для зниження артеріального тиску"
      },
      {
        id: 3,
        image: "3.png",
        name: "Аторвастатин-Тева",
        description:
          "Препарат, що знижує рівень холестерину і тригліцеридів. Один з статинів"
      },
      {
        id: 4,
        image: "4.png",
        name: "Бетагістин-Тева",
        description: "Препарат для лікування вестибулярних порушень"
      },
      {
        id: 5,
        image: "5.png",
        name: "Гастро-Тева",
        description:
          "Антацид. Комбінований препарат та комплексна сполука алюмінію, кальцію та магнію"
      },
      {
        id: 6,
        image: "6.png",
        name: "Гліклазид-Тева",
        description: "Цукрознижувальний засіб, похідне сульфонілсечовини"
      },
      {
        id: 7,
        image: "7.png",
        name: "Диклофенак-Тева",
        description:
          "Нестероїдний препарат із вираженими знеболювальними/протизапальними властивостями"
      },
      {
        id: 8,
        image: "8.png",
        name: "Доксициклін-Тева",
        description: "Антибіотик для системного застосування"
      },
      {
        id: 9,
        image: "9.png",
        name: "Еналаприл-Тева",
        description:
          "Інгібітор ангіотензинперетворювального ферменту. Для лікування артеріальної гіпертензії та лікування клінічно вираженої серцевої недостатності"
      },
      {
        id: 10,
        image: "10.png",
        name: "Ехінацея-Тева",
        description:
          "Рослинний стимулятор, який має імуномодулюючу і протизапальну дію"
      },
      {
        id: 11,
        image: "11.png",
        name: "Індапамід-Тева SR",
        description:
          "Препарат, що впливає на серцево-судинну систему. Нетіазидний діуретик з помірно вираженою активністю"
      },
      {
        id: 12,
        image: "12.png",
        name: "Індовазин-Тева",
        description:
          "Мазь, до складу якої входять індометацин та троксерутин. Ангіопротектор. Капіляростабілізуючий засіб"
      },
      {
        id: 13,
        image: "13.png",
        name: "Клопідогрел-Тева",
        description: "Інгібітор агрегації тромбоцитів"
      },
      {
        id: 14,
        image: "14.png",
        name: "Ксило-Тева",
        description:
          "Протинабряковий препарат для місцевого застосування. Звужує кровоносні судини, тим самим усуває набряк слизової оболонки у носі та ділянці носоглотки. Полегшує дихання через ніс під час застуди та риніту"
      },
      {
        id: 15,
        image: "15.png",
        name: "Левофлоксацин-Тева",
        description: "Антибіотик із групи фторхінолонів"
      },
      {
        id: 16,
        image: "16.png",
        name: "Лізотіазид-Тева",
        description:
          "Комбінація інгібітора ангіотензинперетворювального ферменту (іАПФ) і діуретика"
      },
      {
        id: 17,
        image: "17.png",
        name: "Лозартан-Тева",
        description: "Антагоніст рецепторів ангіотензину ІІ"
      },
      {
        id: 18,
        image: "18.png",
        name: "Мелоксикам-Тева",
        description:
          "Нестероїдний протизапальний та протиревматичний препарат. Оксикам"
      },
      {
        id: 19,
        image: "19.png",
        name: "Небіволол-Тева",
        description: "Селективний блокатори β-адренорецепторів"
      },
      {
        id: 20,
        image: "20.png",
        name: "Неуробекс-Тева",
        description: "Комплекс вітамінів: В1, В6 та В12"
      },
      {
        id: 21,
        image: "21.png",
        name: "Омепразол-Тева",
        description:
          "Препарат для лікування пептичної виразки і гастроезофагеальної рефлюксної хвороби. Інгібітор протонної помпи"
      },
      {
        id: 22,
        image: "22.png",
        name: "Пантопразол-Тева",
        description:
          "Препарат для лікування кислотозалежних захворювань. Інгібітор протонної помпи"
      },
      {
        id: 23,
        image: "23.png",
        name: "Раміприл-Тева",
        description: "Інгібітор ангіотензинперетворювального ферменту (АПФ)"
      },
      {
        id: 24,
        image: "24.png",
        name: "Риб'ячий жир-Тева",
        description:
          "Препарат риб’ячого походження з двома поліненасиченими жирними кислотами - ейкозапентаєновою та докозагексаєновою"
      },
      {
        id: 25,
        image: "25.png",
        name: "Симвастатин-Тева",
        description: "Гіполіпідемічний препарат. Один з статинів"
      },
      {
        id: 26,
        image: "26.png",
        name: "Стоптусин-Тева",
        description:
          "Комбінований препарат з протикашльовою, муколітичною та відхаркувальною дією"
      },
      {
        id: 27,
        image: "27.png",
        name: "Тевалор-Тева",
        description:
          "Комбінований препарат з місцевою антисептичною, антибактеріальною і протигрибковою дією. Застосовується при запаленнях горла"
      },
      {
        id: 28,
        image: "28.png",
        name: "Торасемід-Тева",
        description: "Сечогінний препарат. Петлевий діуретик"
      },
      {
        id: 29,
        image: "29.png",
        name: "Флуконазол-Тева",
        description: "Протигрибковий препарат"
      },
      {
        id: 30,
        image: "30.png",
        name: "Цетиризин-Тева",
        description: "Антигістамінний препарат"
      }
    ],
    questions: [
      {
        id: 1,
        cx: 655.5,
        cy: 372.5,
        image: "1.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 30,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 1,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 6,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 4,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 2,
            isCorrect: false,
            isSelecter: false
          }
        ]
      },
      {
        id: 2,
        cx: 256.5,
        cy: 771.5,
        image: "2.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 29,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 5,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 2,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 6,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 1,
            isCorrect: false,
            isSelecter: false
          }
        ]
      },
      {
        id: 3,
        cx: 1299.5,
        cy: 640.5,
        image: "3.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 28,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 7,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 2,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 3,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 9,
            isCorrect: false,
            isSelecter: false
          }
        ]
      },
      {
        id: 4,
        cx: 1395.5,
        cy: 274.5,
        image: "4.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 27,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 8,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 30,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 3,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 4,
            isCorrect: true,
            isSelecter: false
          }
        ]
      },
      {
        id: 5,
        cx: 1307.5,
        cy: 254.5,
        image: "5.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 26,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 5,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 19,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 9,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 4,
            isCorrect: false,
            isSelecter: false
          }
        ]
      },
      {
        id: 6,
        cx: 132.5,
        cy: 417.5,
        image: "6.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 25,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 10,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 6,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 5,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 3,
            isCorrect: false,
            isSelecter: false
          }
        ]
      },
      {
        id: 7,
        cx: 1465.5,
        cy: 541.5,
        image: "7.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 24,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 11,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 6,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 7,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 21,
            isCorrect: false,
            isSelecter: false
          }
        ]
      },
      {
        id: 8,
        cx: 1392.5,
        cy: 805.5,
        image: "8.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 23,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 12,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 7,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 28,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 8,
            isCorrect: true,
            isSelecter: false
          }
        ]
      },
      {
        id: 9,
        cx: 102.5,
        cy: 141.5,
        image: "9.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 22,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 9,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 8,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 13,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 15,
            isCorrect: false,
            isSelecter: false
          }
        ]
      },
      {
        id: 10,
        cx: 512.5,
        cy: 640.5,
        image: "10.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 21,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 14,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 10,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 5,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 9,
            isCorrect: false,
            isSelecter: false
          }
        ]
      },
      {
        id: 11,
        cx: 811.5,
        cy: 765.5,
        image: "11.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 20,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 15,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 18,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 11,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 10,
            isCorrect: false,
            isSelecter: false
          }
        ]
      },
      {
        id: 12,
        cx: 285.5,
        cy: 98.5,
        image: "12.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 19,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 16,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 11,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 25,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 12,
            isCorrect: true,
            isSelecter: false
          }
        ]
      },
      {
        id: 13,
        cx: 645.5,
        cy: 864.5,
        image: "13.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 18,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 13,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 17,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 8,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 12,
            isCorrect: false,
            isSelecter: false
          }
        ]
      },
      {
        id: 14,
        cx: 1400.5,
        cy: 906.5,
        image: "14.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 17,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 18,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 14,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 22,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 13,
            isCorrect: false,
            isSelecter: false
          }
        ]
      },
      {
        id: 15,
        cx: 973.5,
        cy: 607.5,
        image: "15.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 16,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 19,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 11,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 15,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 14,
            isCorrect: false,
            isSelecter: false
          }
        ]
      },
      {
        id: 16,
        cx: 184.5,
        cy: 692.5,
        image: "16.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 15,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 20,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 28,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 21,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 16,
            isCorrect: true,
            isSelecter: false
          }
        ]
      },
      {
        id: 17,
        cx: 1375.5,
        cy: 129.5,
        image: "17.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 14,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 17,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 16,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 12,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 2,
            isCorrect: false,
            isSelecter: false
          }
        ]
      },
      {
        id: 18,
        cx: 445.5,
        cy: 906.5,
        image: "18.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 13,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 17,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 18,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 21,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 19,
            isCorrect: false,
            isSelecter: false
          }
        ]
      },
      {
        id: 19,
        cx: 466.5,
        cy: 83.5,
        image: "19.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 12,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 22,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 6,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 19,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 18,
            isCorrect: false,
            isSelecter: false
          }
        ]
      },
      {
        id: 20,
        cx: 990.5,
        cy: 83.5,
        image: "20.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 11,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 23,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 21,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 19,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 20,
            isCorrect: true,
            isSelecter: false
          }
        ]
      },
      {
        id: 21,
        cx: 514.5,
        cy: 756.5,
        image: "21.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 10,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 21,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 24,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 22,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 20,
            isCorrect: false,
            isSelecter: false
          }
        ]
      },
      {
        id: 22,
        cx: 124.5,
        cy: 542.5,
        image: "22.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 9,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 25,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 22,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 18,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 21,
            isCorrect: false,
            isSelecter: false
          }
        ]
      },
      {
        id: 23,
        cx: 719.5,
        cy: 595.5,
        image: "23.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 8,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 26,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 28,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 23,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 22,
            isCorrect: false,
            isSelecter: false
          }
        ]
      },
      {
        id: 24,
        cx: 1183.5,
        cy: 871.5,
        image: "24.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 7,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 27,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 20,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 23,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 24,
            isCorrect: true,
            isSelecter: false
          }
        ]
      },
      {
        id: 25,
        cx: 782.5,
        cy: 313.5,
        image: "25.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 6,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 25,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 28,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 30,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 24,
            isCorrect: false,
            isSelecter: false
          }
        ]
      },
      {
        id: 26,
        cx: 369.5,
        cy: 237.5,
        image: "26.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 5,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 29,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 26,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 27,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 25,
            isCorrect: false,
            isSelecter: false
          }
        ]
      },
      {
        id: 27,
        cx: 645.5,
        cy: 100.5,
        image: "27.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 4,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 30,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 23,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 27,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 26,
            isCorrect: false,
            isSelecter: false
          }
        ]
      },
      {
        id: 28,
        cx: 1110.5,
        cy: 820.5,
        image: "28.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 3,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 27,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 1,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 29,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 28,
            isCorrect: true,
            isSelecter: false
          }
        ]
      },
      {
        id: 29,
        cx: 598.5,
        cy: 750.5,
        image: "29.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 2,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 29,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 28,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 16,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 26,
            isCorrect: false,
            isSelecter: false
          }
        ]
      },
      {
        id: 30,
        cx: 1207.5,
        cy: 484.5,
        image: "30.jpg",
        hasCorrectAnswer: false,
        answers: [
          {
            drugId: 1,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 29,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 30,
            isCorrect: true,
            isSelecter: false
          },
          {
            drugId: 3,
            isCorrect: false,
            isSelecter: false
          },
          {
            drugId: 25,
            isCorrect: false,
            isSelecter: false
          }
        ]
      }
    ]
  },
  getters: {
    getQuestions(state) {
      return state.questions;
    },
    getCurrentQuestion(state) {
      return state.currensQuestion;
    },
    getIsQuestionModal(state) {
      return state.isQuestionModal;
    },
    getDrugs(state) {
      return state.drugs;
    },
    getIsDrugModal(state) {
      return state.isDrugModal;
    },
    getGameProgress(state) {
      return state.gameProgress;
    },
    getIsReloadGameModal(state) {
      return state.isReloadGameModal;
    },
    getIsOnlySpecialistModal(state) {
      return state.isOnlySpecialistModal;
    },
    getIsNotSpecialistModal(state) {
      return state.isNotSpecialistModal;
    },
    getIsWelcomeModal(state) {
      return state.isWelcomeModal;
    },
    getIsHowToStartModal(state) {
      return state.isHowToStartModal;
    },
    getIsHowToPlayModal(state) {
      return state.isHowToPlayModal;
    },
    getIsGameOwerModal(state) {
      return state.isGameOwerModal;
    }
  },
  mutations: {
    updateGameData(state) {
      if (localStorage.getItem("game") !== null) {
        state.questions = JSON.parse(localStorage.getItem("game"));
      }
      if (localStorage.getItem("gameProgress") !== null) {
        state.gameProgress = JSON.parse(localStorage.getItem("gameProgress"));
      }
    },
    saveGame(state) {
      localStorage.setItem("game", JSON.stringify(state.questions));
      localStorage.setItem("gameProgress", JSON.stringify(state.gameProgress));
    },
    selectQuestion(state, id) {
      state.currensQuestion = state.questions[id];
    },
    setQuestionModal(state, value) {
      state.isQuestionModal = value;
    },
    setQuestionAnswer(state) {
      state.questions.forEach(question => {
        if (question.id === state.currensQuestion.id) {
          question.hasCorrectAnswer = true;
          state.gameProgress += 1;
        }
      });
    },
    setDrugModal(state, value) {
      state.isDrugModal = value;
    },
    reloadGame(state) {
      localStorage.removeItem("game");
      localStorage.removeItem("gameProgress");
      localStorage.removeItem("gameOwer");
      state.gameProgress = 0;
      state.questions.forEach(question => {
        question.hasCorrectAnswer = false;
      });
    },
    setReloadGameModal(state, value) {
      state.isReloadGameModal = value;
    },
    setOnlySpecialistModal(state, value) {
      state.isOnlySpecialistModal = value;
    },
    setNotSpecialistModal(state, value) {
      state.isNotSpecialistModal = value;
    },
    setIsWelcomeModal(state, value) {
      state.isWelcomeModal = value;
    },
    setIsHowToStartModal(state, value) {
      state.isHowToStartModal = value;
    },
    setIsHowToPlayModal(state, value) {
      state.isHowToPlayModal = value;
    },
    setIsGameOwerModal(state, value) {
      state.isGameOwerModal = value;
    }
  }
});

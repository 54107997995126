<template>
  <div class="modal" @click.self="closeModal()">
    <div class="modal-main">
      <div class="modal-main__content">
        <div class="modal-main__text">
          Вибачте, будь ласка, інформація призначена лише для спеціалістів
          галузі охорони здоров’я.
        </div>
        <div class="modal-main__btn" @click="closeModal()">Добре</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "NotSpecialistModal",
  methods: {
    ...mapMutations(["setNotSpecialistModal"]),
    closeModal() {
      this.setNotSpecialistModal(false);
      window.location.reload();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 90, 133, 0.6);
  z-index: 100;

  &-main {
    position: absolute;
    width: 100%;
    max-width: 420px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    box-shadow: 0px 7px 61px rgba(0, 0, 0, 0.17);
    &__content {
      padding: 31px 80px 44px;
    }
    &__text {
      margin-bottom: 30px;
      font-weight: 300;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }
    &__btn {
      width: 120px;
      height: 42px;
      margin: auto;
      background-color: $blue;
      border-radius: 100px;
      font-weight: 600;
      font-size: 16px;
      line-height: 40px;
      text-align: center;
      color: #fff;
      transition: all 0.5s;
      cursor: pointer;
      &:hover {
        background-color: $purple;
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .modal {
    &-main {
      max-width: 375px;
      &__content {
        padding: 30px 20px 30px;
      }
      &__text {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
}
</style>
